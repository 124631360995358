import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
const versionl = environment.version;

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
  { path: '/listar-eventos'             , title: 'Listar eventos',    icon: 'pe-7s-ticket', class: '' },
  { path: '/listar-usuarios'            , title: 'Usuarios',          icon: 'pe-7s-users', class: '' },
  { path: '/listar-admin'               , title: 'Administradores',   icon: 'pe-7s-id', class: '' },
  { path: '/listar-estadios'            , title: 'Locaciones',        icon: 'pe-7s-map', class: '' },
  { path: '/listar-palcos'              , title: 'Palcos',            icon: 'pe-7s-users', class: '' },
  { path: '/listar-pagos'               , title: 'Pagos',             icon: 'pe-7s-cash', class: '' },
  { path: '/listar-extras'              , title: 'Extras',            icon: 'pe-7s-rocket', class: '' },
  { path: '/listar-mensajes'            , title: 'Mensajes',          icon: 'pe-7s-mail', class: '' },
  { path: '/listar-configuraciones'     , title: 'Configuraciones',   icon: 'pe-7s-config', class: '' },
   
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  public version = versionl;
  constructor( 
    private router: Router
  ) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };
  logout(){
    this.router.navigateByUrl('/dashboard');
  }
}
