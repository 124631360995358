import { Component, OnInit } from '@angular/core';
import {Auth} from 'aws-amplify';
import { FormsModule } from '@angular/forms'; 
import { Router } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.css']
})
export class RegistroComponent implements OnInit {
    email:string = "";
    nombre: string = "";
    password:string = "";
    givenName:string = "";
    familyName:string = "";
    constructor(private router:Router) { }
    ngOnInit(): void {
    }

    registrar(){

    }

}
