import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { LoginRouting } from './login.routing';
import { RegistroComponent } from './registro/registro.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActualizaPasswordComponent } from './components/actualiza-password/actualiza-password.component';
import { LoginComponent } from './login/login.component';
import { FormularioLoginComponent } from './components/formulario-login/formulario-login.component';
import { FormularioRegistroComponent } from './components/formulario-registro/formulario-registro.component';
import { FormularioAuthComponent } from './components/formulario-auth/formulario-auth.component';
import { FormularioActualizaDatosComponent } from './components/formulario-actualiza-datos/formulario-actualiza-datos.component';



@NgModule({
  declarations: [
    RegistroComponent, 
    ActualizaPasswordComponent, 
    FormularioLoginComponent, 
    FormularioRegistroComponent, 
    FormularioAuthComponent, FormularioActualizaDatosComponent, 
  ],
  exports: [
    RegistroComponent, 
    ActualizaPasswordComponent, 
    FormularioLoginComponent, 
    FormularioRegistroComponent, 
    FormularioAuthComponent, 
  ],
  imports: [
    CommonModule, 
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(LoginRouting),
  ]
})
export class LoginModule { }
