import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router'; 
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  
  public loginForm = this.fb.group({
    email:    [this.authService.leerUsr().email    || '' , [ Validators.required, Validators.email ] ],
    password: [this.authService.leerUsr().password || '', Validators.required ],
    remember: [this.authService.leerUsr().remember || false]
  });


  public user       = "";
  public password   = "";
  public remember   = false;

  constructor(
    private router: Router,
    public authService: AuthService,
    private fb: FormBuilder,
  ) { }

  
  irSiguiente(){
    this.router.navigateByUrl('/paso-1');
  }

  login(){
    this.authService.iniciarSesion( this.loginForm.get('email').value, this.loginForm.get('password').value, this.loginForm.get('remember').value);
  }
  
  ngOnInit(): void { 
  }
}
