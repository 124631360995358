import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core'; 
import { Router } from '@angular/router'; 
import { NgxSpinnerService } from "ngx-spinner";
import { NotificacionService } from './notificacion.service';
import jwt_decode from "jwt-decode";
import { environment } from '../../environments/environment';
const base_url = environment.lambda_url;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public emailRegistro = "";
  private loginError = false;
  private loginErrorMsg = '';
  private pasoLogin = 'inicio'; //inicio actualiza-password

  constructor(
    private notificacion: NotificacionService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private http: HttpClient, 
  ) { }
  
  public get token(): string {
    return localStorage.getItem('token') || '';
  }  
  public get headers() {
    return {
      headers: {
        'x-token': this.token
      }
    }
  }
  guardarToken(token: string){
    localStorage.setItem('token', token );  
  }
  cerrarSesion() { 
      localStorage.removeItem('token'); 
  }
  guaradarRecordar(email: String, password: String){ 
    let coded = btoa(JSON.stringify({ email: email, password: password, remember: true }));
    localStorage.setItem('sp', coded);
  }
  quitarRecordar(){ 
      localStorage.removeItem('sp');
  }
  leerUsr(){
    let coded = localStorage.getItem('sp');
    if(coded === null){
      return JSON.parse('{"email":"","password":"","remember":false}');
    }
    return JSON.parse(atob(coded)); 
  }
  getIdJugador(){
    return this.getDecodedAccessToken().uid || '';
  }

  getNombreJugador(){
    return this.getDecodedAccessToken().nombre || 0;
  }

  getDecodedAccessToken(): any {
    try{
        return jwt_decode( this.token ) || {
            uid: '',
            eid: '',
            email: '',
            nombre: '',
            monedas: 0};
    }
    catch(Error){
        return {
            uid: '',
            eid: '',
            email: '',
            nombre: '',
            monedas: 0}; 
    }
  }
 


  setPaso(paso: string){
    this.pasoLogin = paso;
  }
  getPaso(){
    return this.pasoLogin;
  }
  irInicio(){
    this.router.navigateByUrl('/paso-1');
  }
  irRegistro(){
    this.setPaso('registro');
  }
  irLogin(){
    this.setPaso('inicio'); 
  }
  irActualizaPassword(){
    this.setPaso('actualiza-password'); 
  }
  irSFA(){ //Validacion de codigo
    this.setPaso('sfa'); 
  }

  getErrorLogin(){
    return this.loginError;
  }
  errorLoginMensaje(){
    return this.loginErrorMsg;
  }
  restaurarErrores(){
    this.loginError = false;
    this.loginErrorMsg = '';
    this.emailRegistro = '';
  }
  errorLogin(mensaje: string){
    this.loginError = true;
    this.loginErrorMsg = mensaje;
  }

  actualizarPassword( oldPassword: string, newPassword: string){
    this.spinner.show(); 
  }
  irHome(){
    this.router.navigateByUrl('/paso-1');
  }
  async iniciarSesion(email: string, password: string, remember: boolean){
    this.restaurarErrores(); 
    this.spinner.show();
    try {
      this.http.post(`${ base_url }/api/administradores/login`,{ email: email, password: password },  this.headers )
      .subscribe((data: any) => {

        if(data.ok){

          if(remember){
            this.guaradarRecordar(email, password);
          }else{
            this.quitarRecordar();
          }
          this.notificacion.showNotification('top','center','success','Sesión iniciada correctamente');
          this.irHome();
          this.spinner.hide();
  
        }
      }, (err: any) => {

        this.quitarRecordar();
        this.notificacion.showNotification('top','center','danger','Error al iniciar sesión');
        this.spinner.hide();

      });
    } catch (error) {

      this.notificacion.showNotification('top','center','danger','Excepción al iniciar sesión');
      this.spinner.hide(); 

      if(error.code == "InvalidParameterException"){  
        this.errorLogin('Usuario o contraseña inválidos');
      }else{ 
        this.errorLogin('Falló la autenticación'); 
      }
    }
  }

  async registrar(email: string, password: string, nombre: string, apellido: string){
    this.spinner.show();
    try {  

      this.spinner.hide();
      this.irLogin();  
      this.notificacion.showNotification('top','center','success','Registrado correctamente');
    } catch (error) { 
      this.spinner.hide();
      this.notificacion.showNotification('top','center','danger','Error al registrar');
      console.log('error signing up:', error);
    }
  }
}
