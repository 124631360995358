import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-formulario-actualiza-datos',
  templateUrl: './formulario-actualiza-datos.component.html',
  styleUrls: ['./formulario-actualiza-datos.component.css']
})
export class FormularioActualizaDatosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
