import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
declare var $: any;

@Component({
  selector: 'app-formulario-login',
  templateUrl: './formulario-login.component.html',
  styleUrls: ['./formulario-login.component.css']
})
export class FormularioLoginComponent implements OnInit {

  public email = "";
  public password = "";

  constructor(
    public authService: AuthService
  ) { }

  iniciarSesion(){
    this.authService.iniciarSesion(this.email, this.password, true);
  }

  ngOnInit(): void {
    var formInputs = $('input[type="email"],input[type="password"]');
    formInputs.focus(function() {
         $(this).parent().children('p.formLabel').addClass('formTop');
         $('div#formWrapper').addClass('darken-bg');
         $('div.logo').addClass('logo-active');
    });
    formInputs.focusout(function() {
      if ($.trim($(this).val()).length == 0){
      $(this).parent().children('p.formLabel').removeClass('formTop');
      }
      $('div#formWrapper').removeClass('darken-bg');
      $('div.logo').removeClass('logo-active');
    });
    $('p.formLabel').click(function(){
       $(this).parent().children('.form-style').focus();
    }); 
  }
}
