import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgxSpinnerModule } from "ngx-spinner";
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';

import { AppRoutingModule } from './app.routing';
import { NavbarModule } from './shared/navbar/navbar.module';
import { FooterModule } from './shared/footer/footer.module';
import { SidebarModule } from './sidebar/sidebar.module';

import { AppComponent } from './app.component';
 
import { LoginComponent } from './login/login/login.component';
import { LoginModule } from './login/login.module'; 
import { NgxPaginationModule } from 'ngx-pagination';  
 
/* COGNITO */
import { BrowserModule } from '@angular/platform-browser'; 
import { AdminLayoutComponent } from './pages/admin-layout.component'; 
  
/* FIN COGNITO */

export const MY_MOMENT_FORMATS = {
  parseInput: 'l LT',
  fullPickerInput: 'l LT',
  datePickerInput: 'l',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};


@NgModule({
  imports: [
    NgxPaginationModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
    NavbarModule,
    FooterModule,
    SidebarModule,
    AppRoutingModule,
    NgxSpinnerModule,
    LoginModule,
    BrowserModule, //cognito
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LoginComponent,  
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA], 
  providers: [
    //{provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS},
    {provide: OWL_DATE_TIME_LOCALE, useValue: 'es'},

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
